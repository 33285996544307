import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faFile } from '@fortawesome/free-solid-svg-icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import {
  BooleanFunctionType,
  CoreAttachmentDto, NumberFunctionType,
} from '../../../core/types/coreTypes';
import { bytesToMegabytes } from '../../utils/attachmentsHandlers';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import Tr from '../../../core/components/table-components/Tr';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import Switch from '../../../core/components/switch/Switch';

type AttachmentsRowProps = {
  attachment: CoreAttachmentDto,
  allowedManage: boolean,
  checked: boolean,
  checkRows: NumberFunctionType,
  onClick: NumberFunctionType,
  actions: Array<ItemType>,
  disabledActions?: boolean,
  changeShowInLinkedJobsToggle?: (attachmentId: number, value: boolean, setSwitchLoading: BooleanFunctionType) => void,
}
const AttachmentsRow: React.FC<AttachmentsRowProps> = ({
  attachment,
  allowedManage,
  checked,
  checkRows,
  onClick,
  actions,
  disabledActions,
  changeShowInLinkedJobsToggle,
}) => {
  const defaultAttachValues = {
    size: bytesToMegabytes(attachment.sizeBytes),
    uploadDate: localDateFormatHandler('DD/MM/YYYY HH:mm', attachment.uploadDate),
    comment: attachment.comment,
  };

  const [switchLoading, setSwitchLoading] = useState(false);

  const { comment, ...rest } = defaultAttachValues;
  const attachProperties = changeShowInLinkedJobsToggle ? {
    ...rest,
    showInLinkedJobs: attachment.showInLinkedJobs,
    comment,
  } : defaultAttachValues;

  const renderCellValue = (key: string, value: string | number | boolean | null) => {
    if (key === 'comment') {
      return <TruncateTooltip
        value={value as string | number | null}
        table
      />;
    }
    if (key === 'showInLinkedJobs' && changeShowInLinkedJobsToggle) {
      return <Switch
        disabled={!allowedManage}
        checked={value as boolean}
        onChange={(v) => {
          changeShowInLinkedJobsToggle(attachment.id, v, setSwitchLoading);
        }}
        loading={switchLoading}
      />;
    }
    return <span>{value}</span>;
  };

  return (
    <Tr>
      {allowedManage && (
        <TdNew>
          <Checkbox
            checked={checked}
            onChange={() => checkRows(attachment.id)}
            disabled={disabledActions || attachment.isFromLinkedEntity}
            table
          />
        </TdNew>
      )}
      <TdNew className="attachmentsTd--filename">
        <span
          className="attachmentsTd__file"
          onClick={() => {
            !disabledActions && onClick(attachment.id);
          }}
          tabIndex={0}
          role="button"
        >
          <FontAwesomeIcon icon={faFile} className="attachmentsTd__file-icon" />
          <TruncateTooltip
            value={attachment.name}
            table
          />
        </span>
      </TdNew>
      {allowedManage && (
        <TdNew action>
          <Dropdown
            icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
            menuItems={actions}
            disabled={disabledActions || attachment.isFromLinkedEntity}
          />
        </TdNew>
      )}
      {Object.entries(attachProperties).map(([key, value]) => (
        <TdNew key={key} className={`attachmentsTd--${key}`}>
          {renderCellValue(key, value)}
        </TdNew>))}
    </Tr>
  );
};

export default AttachmentsRow;
